import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8d986c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_headline = _resolveComponent("page-headline")!
  const _component_home_the_best = _resolveComponent("home-the-best")!
  const _component_home_our_stories = _resolveComponent("home-our-stories")!
  const _component_launch_your_product = _resolveComponent("launch-your-product")!

  return (_ctx.page)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_page_headline, { content: _ctx.page }, null, 8, ["content"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_home_the_best, { "expand-all": true }),
          _createVNode(_component_home_our_stories),
          _createVNode(_component_launch_your_product)
        ])
      ]))
    : _createCommentVNode("", true)
}