
import { defineComponent } from "vue";
import { contentsService, StaticContent } from "@/services/contents.service";
import PageHeadline from "@/common/PageHeadline.vue";
import HomeTheBest from "@/modules/our-services/HomeTheBest.vue";
import HomeOurStories from "@/modules/success-stories/HomeOurStories.vue";
import LaunchYourProduct from "@/components/LaunchYourProduct.vue";
import { setPageDescription, setPageTitle } from "@/common/meta.utils";

export default defineComponent({
  name: "OurServices",
  components: { LaunchYourProduct, HomeOurStories, HomeTheBest, PageHeadline },
  data() {
    return {
      page: undefined as StaticContent | undefined,
    };
  },
  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      contentsService.findBySlug("page-our-services").then((page) => {
        this.page = page;
        setPageTitle(page?.title);
        setPageDescription(page?.lead);
      });
    },
  },
});
